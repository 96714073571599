@font-face {
  font-family: 'TT Fors Trial';
  src:
    local('TT Fors Trial'),
    url(./assets/fonts/TT Fors Trial Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Sora';
  src:
    local('Sora'),
    url(./assets/fonts/Sora-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family:
    'TT Fors Trial',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Sora' !important;
}

p,
div,
span {
  font-family: 'TT Fors Trial' !important;
}
